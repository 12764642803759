<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <div class="vue__editor">
            <div v-if="!fullScreen">
                <button @click="setFullScreen(true)" type="button" class="vue__editor__full-screen-btn">
                    <i class="fa fa-expand-arrows-alt primary-text"></i>
                </button>
                <codemirror
                    :style="{ 'font-size': `${codeOptions.fontSize}px` }"
                    ref="editor"
                    v-model="local"
                    :options="codeOptions"
                />
            </div>
            <div class="vue__editor__full-wrapper" v-else>
                <EditorSplit :firstSideSize.sync="codeOptions.editorSize" :vertical="codeOptions.vertical">
                    <template #first-side>
                        <form>
                            <codemirror
                                :style="{ 'font-size': `${codeOptions.fontSize}px` }"
                                ref="editor"
                                v-model="local"
                                :options="codeOptions"
                            />
                            <EditorActionHeader
                                @saveAndExit="saveAndExit"
                                @exitFromFullScreen="exitWithoutSaving"
                                :size="codeOptions.editorSize"
                                :vertical.sync="codeOptions.vertical"
                                :font-size="codeOptions.fontSize"
                                @update:fontSize="changeFontSize"
                                :error-message="errorMessage"
                                @format="format"
                                @save="save"
                            />
                        </form>
                    </template>
                    <template #second-side>
                        <VueEditorLiveTemplate ref="editorLiveTemplate" />
                    </template>
                </EditorSplit>
            </div>
        </div>
    </BaseControl>
</template>
<script>
import "@Platon/components/builder/items/Loader"
import "@Platon/external/codemirror/CodeMirrorImports.js"
import formatter from "@Platon/external/codemirror/formatter"
import defaultTemplate from "@Platon/external/codemirror/defaultTemplate"
import EditorSplit from "./EditorSplit.vue"
import EditorActionHeader from "./EditorActionHeader.vue"
import EditorDefaultOptionsGetter, { setConfig } from "./editorDefaultOptionsGetter"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import FormDataMixin from "@Platon/components/form/FormDataMixin"
import VueEditorLiveTemplate from "@Platon/components/form/controls/VueEditorControl/VueEditorLiveTemplate.vue"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"

export default {
    props: {
        value: {}
    },
    mixins: [InputControl2Mixin, ValidationMixin, FormDataMixin],
    data() {
        return {
            local: defaultTemplate,
            fullScreen: false,
            errorMessage: "",
            uniqueTemplateId: "template-id",
            codeOptions: EditorDefaultOptionsGetter({ disabled: this.disabled, readonly: this.readonly })
        }
    },
    mounted() {
        if (this.value) {
            this.local = this.value
        }
    },
    components: { VueEditorLiveTemplate, BaseControl, EditorActionHeader, EditorSplit },
    watch: {
        "codeOptions.vertical"() {
            setTimeout(() => {
                this.runReload()
            }, 100)
        }
    },
    methods: {
        exitWithoutSaving() {
            if (this.value !== this.local) {
                this.$platonApp.dangerToast(
                    this.$l("platon.attention", "Диққат"),
                    this.$l("platon.editor_exit_text", "Ўзгартиришларингиз сақлаб қолинмади")
                )
            }
            this.setFullScreen(false)
        },
        setFullScreen(val) {
            this.fullScreen = val
            if (val) {
                this.$nextTick(() => {
                    this.runReload()
                })
            }
        },
        refreshEditor() {
            this.$refs.editor.refresh()
        },
        changeFontSize(fs) {
            this.codeOptions.fontSize = fs
            this.refreshEditor()
            setConfig("fontSize", fs)
        },
        async saveAndExit() {
            await this.save()
            this.setFullScreen(false)
        },
        async runReload() {
            const { editorLiveTemplate } = this.$refs
            return editorLiveTemplate.reload(this.local)
        },
        format() {
            this.value = formatter(this.value)
        },
        async save() {
            this.errorMessage = ""
            try {
                const { content, js, css } = await this.runReload()

                const keyNames = this.getPropNames

                this.$form && this.$form.$emit("formSetData", keyNames.content, content)
                this.$form && this.$form.$emit("formSetData", keyNames.css, css)
                this.$form && this.$form.$emit("formSetData", keyNames.js, js)

                this.$emit("input", this.local)
            } catch (e) {
                this.errorMessage = e?.response?.data?.message || e.message
                return Promise.reject(e)
            }
        }
    },

    computed: {
        getPropNames() {
            return (
                (this.item && this.item.propNames) || {
                    content: "content",
                    css: "css",
                    js: "js"
                }
            )
        }
    }
}
</script>
<style lang="scss">
form {
    padding-top: 30px;
}

.vue__editor {
    position: relative;

    &-left {
        position: relative;

        button {
            .short-key {
                font-size: 11px;
            }
        }
    }

    .render-pane {
        overflow: scroll;
    }

    &__action {
        border: none;
        background: transparent;
        color: #fff;
        border-radius: 3px;
        position: relative;
        $self: &;

        &:hover {
            background: #686b7a;

            #{$self}__hint {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }

        &__hint {
            color: #fff;
            width: 180px;
            right: 0;
            top: 100%;
            transition: all 0.25s;
            position: absolute;
            background: #686b7a;
            padding: 5px 6px;
            z-index: 4;
            border-radius: 4px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
        }
    }

    &__error-action {
        border: none;
        background: transparent;
        color: #ce4d4d;
    }

    &__header {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        left: -4px;
        position: fixed;
        top: 0;
        z-index: 3;
        height: 30px;
        background: #282a36;
        justify-content: flex-end;
    }

    &__full-screen-btn {
        border: none;
        z-index: 10;
        background: transparent;
        right: 10px;
        top: 5px;
        position: absolute;
    }

    &__full-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;

        .cm-s-dracula.CodeMirror,
        .cm-s-dracula .CodeMirror-gutters {
            height: calc(100vh - 30px) !important;
        }
    }
}

.cm-s-dracula.CodeMirror {
    height: 35px;
}

.splitpanes__pane {
    height: 100vh;
    overflow: scroll;
}

.splitpanes__pane::-webkit-scrollbar {
    display: none;
}

.CodeMirror {
    border-radius: 0;
}
</style>
