<template>
    <VIframe :css="fullStyles">
        <component :is="dynamicComponent"></component>
    </VIframe>
</template>

<script>
import { API_BASE_URL } from "@Platon/const"
import VIframe from "@Platon/components/form/controls/VueEditorControl/VIframe.vue"

export default {
    components: { VIframe },
    data() {
        return {
            dynamicComponent: null,
            partComponents: {
                content: "",
                js: "",
                css: "",
                components: []
            }
        }
    },
    methods: {
        /**
         * @param rawVue {string}
         */
        async reload(rawVue) {
            await this.parseToHtmlDocumentFromTxt(rawVue)
            this.compile(this.partComponents)
            this.$emit("compiled", this.partComponents)
            return this.getCodeAsString()
        },
        getCodeAsString() {
            let { css, content, js } = this.partComponents
            js = js.replace(/module.exports\s+=/, "")
            js = js.replace(/export\s+?default\s+/, "")
            return {
                css,
                content,
                js
            }
        },

        compile(data) {
            const compileData = { ...data }
            compileData.js = data.js.replace(/export\s+default/, "module.exports = ")
            this.dynamicComponent = this.compileComponent(compileData)
        },

        /**
         * @param content {string}
         */
        async parseToHtmlDocumentFromTxt(content) {
            const doc = document.implementation.createHTMLDocument("")
            doc.body.innerHTML = content
            const elements = []
            for (let it = doc.body.firstChild; it; it = it.nextSibling) {
                elements.push(it)
            }
            await Promise.all(elements.map(this.parseHtmlByTag))
        },
        /**
         * @param el {ChildNode}
         */
        async parseHtmlByTag(el) {
            const parserByTags = {
                TEMPLATE: this.parseTemplate,
                SCRIPT: this.parseScript,
                STYLE: this.parseStyle
            }
            if (!parserByTags[el.nodeName]) return Promise.resolve()
            await parserByTags[el.nodeName](el)
        },

        async parseTemplate(el) {
            this.partComponents.components = await this.getComponentsThroughTemplate(el.innerHTML)
            this.partComponents.content = el.innerHTML
        },

        parseScript(el) {
            this.partComponents.js = el.textContent
            return Promise.resolve()
        },

        async parseStyle(el) {
            const lang = el.getAttribute("lang")
            if (lang && lang === "scss") {
                this.partComponents.css = await this.scssToCss(el.textContent)
                return
            }
            this.partComponents.css = el.textContent
        },

        /**
         * @param content {string}
         * @returns {Promise<string>}
         */
        async scssToCss(content) {
            try {
                const { data } = await $api.post(`${API_BASE_URL}utils/converter`, {
                    sass: content
                })
                return data.css
            } catch (e) {
                return Promise.reject(e)
            }
        },
        async getComponentsThroughTemplate(template) {
            try {
                const { data } = await $api.post(`${API_BASE_URL}utils/components`, {
                    body: template
                })
                return data
            } catch (e) {
                return []
            }
        },
        compileComponent(data) {
            const component = {
                exports: {}
            }
            let rawScript = data.js
            if (!rawScript) rawScript = "module.exports = {}"
            if (rawScript.indexOf("module.exports")) rawScript = "module.exports = " + rawScript
            Function("module", rawScript).call(null, component)
            component.exports.template = data.content
            const components = {}
            if (data.components) {
                data.components.forEach((item) => {
                    components[item.name] = this.compileComponent(item)
                })
            }
            component.exports.components = components
            return () => Promise.resolve(component.exports)
        }
    },
    computed: {
        fullStyles() {
            let css = this.partComponents.css
            this.partComponents.components.forEach((item) => {
                css += item.css || ""
            })
            return css
        }
    }
}
</script>
