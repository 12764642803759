<template>
    <iframe style="height: 100%; width: 100%" @load="renderChildren"></iframe>
</template>

<script>
import Vue from "vue"
export default {
    props: {
        css: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            style: null,
            head: null,
            body: null
        }
    },
    beforeUpdate() {
        this.iApp.children = Object.freeze(this.$slots.default)
    },
    watch: {
        css(val) {
            this.style.textContent = val
        }
    },
    methods: {
        renderChildren() {
            const children = this.$slots.default
            this.body = this.$el.contentDocument.body
            this.head = this.$el.contentDocument.head
            const el = document.createElement("DIV") // we will mount or nested app to this element
            this.style = document.createElement("style") // we will mount or nested app to this element
            this.style.textContent = this.css
            console.log(this.css)
            this.body.appendChild(el)
            this.head.appendChild(this.style)
            const iApp = new Vue({
                name: "iApp",
                //freezing to prevent unnessessary Reactifiation of vNodes
                data: { children: Object.freeze(children) },
                render(h) {
                    return h("div", this.children)
                }
            })
            iApp.$mount(el) // mount into iframe

            this.iApp = iApp // cache instance for later updates
        }
    }
}
</script>
