<template>
    <splitpanes
        style="height: 100vh"
        :vertical="vertical"
        :horizontal="!vertical"
        @resize="onChangeSize"
        class="vue__editor default-theme"
    >
        <pane :size="100 - secondSlideSize" min-size="20" class="vue__editor-left">
            <slot :name="vertical ? 'first-side' : 'second-side'"></slot>
        </pane>

        <pane :size="secondSlideSize">
            <slot :name="vertical ? 'second-side' : 'first-side'"></slot>
        </pane>
    </splitpanes>
</template>

<script>
import { Pane, Splitpanes } from "splitpanes"
import "splitpanes/dist/splitpanes.css"
import { setConfig } from "@Platon/components/form/controls/VueEditorControl/editorDefaultOptionsGetter"

export default {
    name: "EditorSplit",
    props: {
        vertical: Boolean,
        firstSideSize: Number
    },
    data() {
        return {}
    },
    methods: {
        onChangeSize(e) {
            const size = e[0].size
            setConfig("editorSize", size)
            this.$emit("update:firstSideSize", size)
        }
    },
    computed: {
        secondSlideSize() {
            return 100 - this.firstSideSize
        }
    },
    components: { Splitpanes, Pane }
}
</script>
