<template>
    <div class="vue__editor__header" :style="headerStyles">
        <button class="vue__editor__action" @click.prevent="exitFromFullscreen">
            <span class="vue__editor__action__hint"> Down Esc key to exit from fullscreen </span>
            <i class="fa fa-expand-arrows-alt primary-text"></i>
        </button>
        <button class="vue__editor__action" @click.prevent="$emit('save')">
            <span class="vue__editor__action__hint"> Down Ctrl+S | Cmd + S key to save </span>
            <i class="fas fa-save primary-text"></i>
        </button>
        <button class="vue__editor__action" @click.prevent="toggleVertical">
            <span class="vue__editor__action__hint"> Orientation Landscape | Vertical </span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M9 1H3a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2m0 14H3V3h6v12m12-2h-8v2h8v6H9v-1H6v1a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2m2-3l-4-2l1.91-.91A7.516 7.516 0 0 0 14 2.5V1a9 9 0 0 1 9 9Z"
                />
            </svg>
        </button>
        <button class="vue__editor__action" @click.prevent="$emit('format')">
            <span class="vue__editor__action__hint"> Down Ctrl + I | Cmd + I key to format </span>
            <i class="fas fa-sort-amount-up primary-text"></i>
        </button>
        <button class="vue__editor__action" @click.prevent="incrementFontSize">
            <span class="vue__editor__action__hint"> Font size is {{ fontSize }} </span>
            <i class="fas fa-search-plus"></i>
        </button>
        <button class="vue__editor__action" @click.prevent="decrementFontSize">
            <span class="vue__editor__action__hint"> Font size is {{ fontSize }} </span>
            <i class="fas fa-search-minus"></i>
        </button>
        <button type="button" v-if="errorMessage" class="vue__editor__action vue__editor__error-action">
            <span class="vue__editor__action__hint">{{ errorMessage }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" width="15">
                <path
                    d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm32 224c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"
                />
            </svg>
        </button>
    </div>
</template>

<script>
import { setConfig } from "@Platon/components/form/controls/VueEditorControl/editorDefaultOptionsGetter"

export default {
    props: {
        size: Number,
        vertical: Boolean,
        fontSize: Number,
        errorMessage: String
    },
    data() {
        return {}
    },
    methods: {
        toggleVertical(e) {
            e.preventDefault()
            setConfig("vertical", !this.vertical)
            this.$emit("update:vertical", !this.vertical)
        },
        incrementFontSize() {
            if (this.fontSize < 34) {
                this.$emit("update:fontSize", this.fontSize + 1)
            }
        },
        decrementFontSize() {
            if (this.fontSize > 12) {
                this.$emit("update:fontSize", this.fontSize - 1)
            }
        },
        exitFromFullscreen(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$emit("exitFromFullScreen")
        },
        shortKeyEvent(e) {
            this.useKeyUp(e, [
                {
                    key: "s",
                    isCmd: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("save", e)
                    }
                },
                {
                    key: "s",
                    isCtrl: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("save", e)
                    }
                },
                {
                    key: "y",
                    isCmd: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("format", e)
                    }
                },
                {
                    key: "y",
                    isCtrl: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("format", e)
                    }
                },
                {
                    key: "s",
                    isCtrl: true,
                    isShift: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("saveAndExit", e)
                    }
                },
                {
                    key: "s",
                    isCmd: true,
                    isShift: true,
                    cb: () => {
                        e.preventDefault()
                        this.$emit("saveAndExit", e)
                    }
                },
                {
                    key: "Escape",
                    cb: () => {
                        e.preventDefault()
                        this.exitFromFullscreen(e)
                    }
                }
            ])
        },
        useKeyUp(e, keys) {
            keys.forEach((item) => {
                if (
                    item.key === e.key &&
                    (item.isCmd ? e.metaKey : true) &&
                    (item.isCtrl ? e.ctrlKey : true) &&
                    (item.isShift ? e.shiftKey : true)
                ) {
                    item.cb(e)
                }
            })
        }
    },
    computed: {
        headerStyles() {
            const width = this.vertical ? `${this.size}%` : "calc(100% + 4px)"
            const top = this.vertical ? "0" : `calc(${this.size}% + 3px)`
            return {
                width,
                top
            }
        }
    },

    beforeDestroy() {
        document.removeEventListener("keydown", this.shortKeyEvent)
    },
    mounted() {
        document.addEventListener("keydown", this.shortKeyEvent)
    }
}
</script>
