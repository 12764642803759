<template>
    <div>
        <VueEditor @input="(i) => (page = i)" />
        <DynamicPageRenderer :page="page" />
    </div>
</template>
<script>
import VueEditor from "@Platon/components/form/controls/VueEditorControl/VueEditor.vue"

export default {
    data() {
        return {
            page: {
                js: null,
                css: null,
                template: null
            }
        }
    },
    components: { VueEditor },
    computed: {
        console: () => console
    }
}
</script>
