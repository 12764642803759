const $key = "$editorConfig"
let editorConfig = {}
try {
	editorConfig = JSON.parse(localStorage.getItem($key) || "{}")
} catch (e) {
	console.log("Editor config is not loaded")
}
export default ({ disabled, readonly }) => {
	const fontSize = editorConfig.fontSize || 15
	const vertical = typeof editorConfig.vertical === "boolean" ? editorConfig.vertical : true
	const editorSize = editorConfig.editorSize || 50
	return {
		vertical,
		fontSize,
		editorSize,
		styleActiveLine: true,
		styleSelectedText: true,
		smartIndent: true,
		spellcheck: true,
		autoCloseBrackets: true,
		colorize: true,
		matchBrackets: true,
		autocorrect: true,
		autoCloseTags: true,
		showCursorWhenSelecting: true,
		tabSize: 2,
		mode: { name: "vue" },
		foldGutter: true,
		lineWrapping: true,
		gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
		highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
		theme: "dracula",
		lineNumbers: true,
		keyMap: "sublime",
		line: true,
		hintOptions: {
			completeSingle: false
		},
		readOnly: disabled || readonly ? "nocursor" : false
	}
}

/**
 *
 * @param k {string}
 * @param v {any}
 */
export const setConfig = (k, v) => {
	editorConfig[k] = v
	localStorage.setItem($key, JSON.stringify(editorConfig))
}
